import React from "react";
import {
  getTransactionID,
  getTransaction,
  verifyTransaction,
  wipeTransactionID,
  saveTransactionID,
  createTransaction,
} from "../utils/transactionHelpers";
import { Transaction } from "../../API/XFA_DEVICE_API";

import { checkOS, OS, supportedMobileOSes } from "../../System/System";

import { useSearchParams } from "react-router-dom";
import { TokenRequestCustomFlow } from "../Token";
import { useTranslation } from "react-i18next";

export default function useTransaction(navigator: Navigator) {
  const { t } = useTranslation();
  const [error, setError] = React.useState<string>("");
  // System information
  const [os, setOS] = React.useState<undefined | OS>(undefined);
  React.useEffect(() => {
    setOS(checkOS(navigator));
  }, [navigator]);

  const [transaction, setTransaction] = React.useState<undefined | Transaction>(
    undefined
  );
  const [transactionIsSkippable, setTransactionIsSkippable] = React.useState<
    boolean | undefined
  >(undefined);

  const [searchParams] = useSearchParams();

  const updateTransaction = React.useCallback((transactionId: string) => {
    getTransaction(transactionId)
      .then((transaction) => {
        if (verifyTransaction(transaction)) {
          setTransaction(transaction);
        } else {
          console.error(
            "Transaction is not valid:" + JSON.stringify(transaction)
          );
          setError("Invalid transaction");
          wipeTransactionID();
        }
      })
      .catch((error) => {
        console.error(error);
        setError("Error fetching transaction");
        wipeTransactionID();
      });
  }, []);

  //check for an existing transaction when the component mounts
  React.useEffect(() => {
    console.log("Transaction hook mounted");

    //check if transaction already is present
    if (transaction) {
      return;
    }

    //check url
    if (searchParams.has("transactionId")) {
      const transactionId = searchParams.get("transactionId");
      if (transactionId) {
        console.log("Received transactionId from url: " + transactionId);
        updateTransaction(transactionId);
        saveTransactionID(transactionId);
      }
      return;
    }

    //parse tokenRequest
    if (searchParams.has("application_id")) {
      try {
        console.log("Attempting to parse tokenRequest");
        console.log(window.location.href);
        console.log(window.location.search);
        const tokenRequest = parseTokenRequest(searchParams, t);
        if (tokenRequest) {
          console.log("Parsed tokenRequest: " + JSON.stringify(tokenRequest));
          wipeTransactionID();

          //if not present, create new transaction
          createTransaction(
            tokenRequest.redirectUrl,
            tokenRequest.applicationId,
            tokenRequest.email
          )
            .then((transaction) => {
              console.log(
                "Created transaction, with transactionId: " +
                  transaction.transactionId
              );
              console.log("Transaction: " + JSON.stringify(transaction));
              setTransaction(transaction);
              saveTransactionID(transaction.transactionId);
            })
            .catch((error) => {
              console.log(error);
              setError((error as Error).message);
            });
        }
      } catch (error) {
        console.log(error);
        setError((error as Error).message);
      }
      return;
    }

    //check storage
    const transactionId = getTransactionID();
    if (transactionId) {
      console.log("Received transactionId from storage: " + transactionId);
      updateTransaction(transactionId);
      return;
    }

    //disable warning for missing dependencies (only run once)
    //eslint-disable-next-line
  }, []);

  //refresh the transaction when the app is refocused
  const onFocus = React.useCallback(() => {
    if (!transaction?.transactionId) return; //no transaction to refresh

    updateTransaction(transaction?.transactionId);
  }, [transaction?.transactionId, updateTransaction]);

  //refresh the transaction periodically (1 second)
  React.useEffect(() => {
    //refresh on mobile
    if (supportedMobileOSes.includes(os as OS)) {
      //don't refresh for MicrosoftEAM
      if (transaction?.application?.type === "MicrosoftEAM") {
        return;
      }

      if (!transaction?.transactionId) return; //no transaction to refresh
      const interval = setInterval(() => {
        updateTransaction(transaction?.transactionId);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [
    os,
    transaction?.transactionId,
    updateTransaction,
    transaction?.application?.type,
  ]);

  //check if transaction skippable
  React.useEffect(() => {
    if (!transaction) {
      return;
    }

    if (transaction.status === "PENDING") {
      if (
        transaction.decisions?.skip?.allowed === true &&
        !transaction.verification //don't show skip button on manual verification (received over email)
      ) {
        setTransactionIsSkippable(true);
      }
    }
  }, [transaction]);

  // every refocus, check again
  React.useEffect(() => {
    window.addEventListener("focus", onFocus);
    return () => {
      window.removeEventListener("focus", onFocus);
    };
  }, [onFocus]);

  return {
    transaction,
    updateTransaction,
    transactionError: error,
    setTransaction,
    transactionIsSkippable,
  };
}

const parseTokenRequest = (
  searchParams: URLSearchParams,
  t: (key: string) => string
): TokenRequestCustomFlow => {
  // get current time
  const now = new Date().getTime();

  // get redirectUrl from search params
  const url = searchParams.get("redirect_url");

  // validate URL with regex
  const urlRegex = new RegExp(
    "^(http|https)://[a-zA-Z0-9-_.]+(:[0-9]{1,5})?(/.*)?$"
  );

  // get email from search params
  const email = searchParams.get("email");
  if (!email) {
    console.error("No email present");
    throw new Error(t("Token.errors.invalidEmail"));
  }

  // get redirectUrl from search params
  if (!(url && urlRegex.test(url))) {
    console.error("No redirectUrl present");
    throw new Error(t("Token.errors.invalidRedirectUrl"));
  }

  // get applicationId from search params
  const applicationId = searchParams.get("application_id");
  if (!applicationId) {
    console.error("No applicationId present");
    throw new Error(t("Token.errors.invalidApplicationId"));
  }

  //extract domain from redirectUrl
  const redirectUrl = new URL(url);

  // create tokenRequest
  const tokenRequest: TokenRequestCustomFlow = {
    requested: now,
    email: email,
    applicationId: applicationId,
    applicationDomain: redirectUrl.hostname,
    redirectUrl: url,
  };

  return tokenRequest;
};
