/* global chrome */
import { useEffect, useState, useCallback } from "react";
import { checkOS, OS, supportedMobileOSes } from "../System/System";
import { NativeClientApi } from "../API/XFA_NATIVE_CLIENT";
import {
  checkExtensionInstalled,
  getCredentialID,
  createCredential,
  getCredential,
  openExtension,
  checkDeviceAffiliatedWithOrganization,
  sendCompleteTransactionToExtension,
  sendInvitationsToExtension,
} from "./Extension";
import { Invitation } from "../Invitations/Invitation";
import {
  LoginBeginResponse,
  RegisterBeginResponse,
} from "../API/XFA_DEVICE_API";

const useClientApi = (navigator: Navigator, browser: typeof chrome) => {
  const [isExtensionInstalled, setIsExtensionInstalled] = useState<
    boolean | undefined
  >(undefined);
  const [isClientAvailable, setIsClientAvailable] = useState<boolean>(false);
  const [isNativeClientAvailable, setIsNativeClientAvailable] =
    useState<boolean>(false);
  const [checking, setChecking] = useState(true);
  const [os, setOS] = useState<OS | undefined>(undefined);

  const deviceClient = new NativeClientApi();

  useEffect(() => {
    setOS(checkOS(navigator));
  }, [navigator]);

  useEffect(() => {
    if (!os) {
      return;
    }

    if (supportedMobileOSes.includes(os)) {
      setIsExtensionInstalled(false);
      setChecking(false);
      return;
    }
    setChecking(true);

    checkExtensionInstalled(
      os,
      setChecking,
      setIsExtensionInstalled,
      navigator,
      browser,
      window
    );
  }, [os, navigator, browser, isExtensionInstalled]);

  useEffect(() => {
    const checkNativeClient = async () => {
      try {
        const response = await deviceClient.default.getInstalled();
        setIsNativeClientAvailable(response);
        setIsClientAvailable(response ? true : isExtensionInstalled || false);
      } catch {
        setIsNativeClientAvailable(false);
        setIsClientAvailable(isExtensionInstalled || false);
      } finally {
        setChecking(false);
      }
    };

    if (isExtensionInstalled === undefined) {
      return;
    }
    if (!isExtensionInstalled) {
      console.log("Checking native client");
      checkNativeClient();
    } else {
      setIsClientAvailable(true);
      setChecking(false);
    }
  }, [isExtensionInstalled, deviceClient.default]);

  const recheck = useCallback(() => {
    if (!os) {
      return;
    }

    if (supportedMobileOSes.includes(os!)) {
      setIsExtensionInstalled(false);
      setChecking(false);
      return;
    }

    setChecking(true);
    setIsExtensionInstalled(undefined);

    checkExtensionInstalled(
      os,
      setChecking,
      setIsExtensionInstalled,
      navigator,
      browser,
      window
    );
  }, [os, navigator, browser]);

  const onFocusDesktop = useCallback(() => {
    recheck();
  }, [recheck]);

  useEffect(() => {
    if (!os) {
      return;
    }

    if (supportedMobileOSes.includes(os)) {
      return;
    }

    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        onFocusDesktop();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [os, onFocusDesktop]);

  const clientApi = {
    getCredentialID: useCallback(
      (callback: (reply: any) => any) => {
        if (isExtensionInstalled) {
          getCredentialID(browser, navigator, callback);
        } else if (isNativeClientAvailable) {
          // TODO: implement getCredentialID for native client
          // deviceClient.getCredentialID(callback);
        }
      },
      [isExtensionInstalled, browser, navigator, isNativeClientAvailable]
    ),

    createCredential: useCallback(
      (callback: (reply: any) => any, body: RegisterBeginResponse) => {
        if (isExtensionInstalled) {
          createCredential(browser, navigator, callback, body);
        } else if (isNativeClientAvailable) {
          // TODO: implement createCredential for native client
          // deviceClient.createCredential(callback, body);
        }
      },
      [isExtensionInstalled, browser, navigator, isNativeClientAvailable]
    ),

    getCredential: useCallback(
      (callback: (reply: any) => any, body: LoginBeginResponse) => {
        if (isExtensionInstalled) {
          getCredential(browser, navigator, callback, body);
        } else if (isNativeClientAvailable) {
          // TODO: implement getCredential for native client
          // deviceClient.getCredential(callback, body);
        }
      },
      [isExtensionInstalled, browser, navigator, isNativeClientAvailable]
    ),

    openExtension: useCallback(() => {
      if (isExtensionInstalled) {
        openExtension(browser, navigator);
      } else if (isNativeClientAvailable) {
        // TODO: add protocol headers for brave & safari support
        const protocolUrl = "xfa://";
        window.location.href = protocolUrl;
      }
    }, [isExtensionInstalled, browser, navigator, isNativeClientAvailable]),

    checkDeviceAffiliatedWithOrganization: useCallback(
      (organizationId: string, callback: (reply: any) => any) => {
        if (isExtensionInstalled) {
          checkDeviceAffiliatedWithOrganization(
            browser,
            navigator,
            organizationId,
            callback
          );
        } else if (isNativeClientAvailable) {
          deviceClient.default
            .getAffiliated(organizationId)
            .then((response) => {
              callback(response.affiliated);
            });
        }
      },
      [
        isExtensionInstalled,
        browser,
        navigator,
        deviceClient.default,
        isNativeClientAvailable,
      ]
    ),

    completeTransaction: useCallback(
      (transactionId: string, callback: (reply: any) => any) => {
        if (isExtensionInstalled) {
          sendCompleteTransactionToExtension(
            browser,
            navigator,
            transactionId,
            callback
          );
        } else if (isNativeClientAvailable) {
          deviceClient.default
            .putCompleteTransaction(transactionId)
            .then(callback);
        }
      },
      [
        isExtensionInstalled,
        browser,
        navigator,
        deviceClient.default,
        isNativeClientAvailable,
      ]
    ),

    sendInvitations: useCallback(
      (
        invitations: Invitation[],
        silent: boolean,
        callback: (reply: any) => any
      ) => {
        if (isExtensionInstalled) {
          sendInvitationsToExtension(
            browser,
            navigator,
            invitations,
            silent,
            callback
          );
        } else if (isNativeClientAvailable) {
          // TODO: implement sendInvitations for native client
          // for (const invitation of invitations) {
          //   deviceClient.default.postInvitation(invitation).then(callback);
          // }
        }
      },
      [isExtensionInstalled, browser, navigator, isNativeClientAvailable]
    ),
  };

  return { clientApi, checking, isClientAvailable, recheck };
};

export default useClientApi;
