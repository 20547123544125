export const deviceApiConfig =
  process.env.REACT_APP_ENVIRONMENT === "test"
    ? {
        BASE: "https://test-device-api.xfa.tech",
      }
    : process.env.REACT_APP_ENVIRONMENT === "production"
    ? {
        BASE: "https://device-api.xfa.tech",
      }
    : {};

export const nativeClientApiConfig =
  process.env.REACT_APP_ENVIRONMENT === "test"
    ? {
        BASE: "http://localhost:11972",
      }
    : process.env.REACT_APP_ENVIRONMENT === "production"
    ? {
        BASE: "http://localhost:11972",
      }
    : {};
