import React from "react";
import "./Decisions.css";
import { Transaction } from "../../../API/XFA_DEVICE_API";
import { useTranslation } from "react-i18next";
import NotNowButton from "../../../General/NotNowButton";
import StatusDecisionListItem from "./StatusDecisionListItem";
import { Button, CircularProgress, Typography } from "@mui/material";

interface DecisionsProps {
  navigator: any;
  browser: any;
  transaction: Transaction;
  endTransaction: (transaction: Transaction) => void;
  refreshTransactionStatus: () => void;
  refreshingTransaction: boolean;
}
const Decisions: React.FC<DecisionsProps> = (props: DecisionsProps) => {
  const { t } = useTranslation();
  // TODO: Delete or update when new design is ready
  const oneHour = 60 * 60;

  const osTimeTillBlocked =
    props.transaction.decisions?.os_up_to_date?.time_till_blocked !== undefined
      ? Math.ceil(
          props.transaction.decisions?.os_up_to_date?.time_till_blocked /
            oneHour
        )
      : undefined;

  const browserTimeTillBlocked =
    props.transaction.decisions?.browser_up_to_date?.time_till_blocked !==
    undefined
      ? Math.ceil(
          props.transaction.decisions?.browser_up_to_date?.time_till_blocked /
            oneHour
        )
      : undefined;
  const isDecisionNotOk = React.useCallback((decision: any) => {
    return !(decision === undefined || decision?.status === "OK");
  }, []);

  const nativeClientNotInstalled = isDecisionNotOk(
    props.transaction.decisions?.installed
  );
  const osNotUpToDate = isDecisionNotOk(
    props.transaction.decisions?.os_up_to_date
  );
  const browserNotUpToDate = isDecisionNotOk(
    props.transaction.decisions?.browser_up_to_date
  );
  const osAutoUpdateNotOK = isDecisionNotOk(
    props.transaction.decisions?.os_autoupdate
  );
  const diskEncryptionNotOK = isDecisionNotOk(
    props.transaction.decisions?.disk_encryption
  );
  const screenlockNotOK = isDecisionNotOk(
    props.transaction.decisions?.screenlock
  );
  const antivirusNotOK = isDecisionNotOk(
    props.transaction.decisions?.antivirus
  );
  const passwordmanagerNotOK = isDecisionNotOk(
    props.transaction.decisions?.passwordmanager
  );
  const notAffiliated = isDecisionNotOk(
    props.transaction.decisions?.affiliated
  );

  const passwordmanagerNotEnabled =
    props.transaction.decisions?.passwordmanager === undefined;

  const blocking = Object.entries(props.transaction.decisions || {}).some(
    ([key, decision]: [string, any]) =>
      decision?.status === "BLOCKING" && key !== "mfa"
  );

  return (
    <div className="contentDecisions">
      {nativeClientNotInstalled ? (
        <StatusDecisionListItem
          browser={props.browser}
          navigator={props.navigator}
          blocking={blocking}
          message={t("decisions.NativeClientNotInstalled")}
          buttonText={t("actions.install")}
          showModal={false}
        />
      ) : (
        <>
          {osNotUpToDate && (
            <StatusDecisionListItem
              browser={props.browser}
              navigator={props.navigator}
              blocking={blocking}
              message={t("decisions.OSOutOfDate")}
              buttonText={t("actions.update")}
              showModal={false}
              timeTillBlocked={osTimeTillBlocked}
            />
          )}
          {osAutoUpdateNotOK && (
            <StatusDecisionListItem
              browser={props.browser}
              navigator={props.navigator}
              blocking={blocking}
              message={t("decisions.osAutoUpdateNotOK")}
              buttonText={t("actions.fix")}
              showModal={false}
            />
          )}
          {browserNotUpToDate && (
            <StatusDecisionListItem
              browser={props.browser}
              navigator={props.navigator}
              blocking={blocking}
              message={t("decisions.BrowserOutOfDate")}
              buttonText={t("actions.update")}
              showModal={false}
              timeTillBlocked={browserTimeTillBlocked}
            />
          )}
          {diskEncryptionNotOK && (
            <StatusDecisionListItem
              browser={props.browser}
              navigator={props.navigator}
              blocking={blocking}
              message={t("decisions.diskEncryptionNotOK")}
              buttonText={t("actions.enable")}
              showModal={false}
            />
          )}
          {screenlockNotOK && (
            <StatusDecisionListItem
              browser={props.browser}
              navigator={props.navigator}
              blocking={blocking}
              message={t("decisions.screenlockNotOK")}
              buttonText={t("actions.activate")}
              showModal={false}
            />
          )}
          {antivirusNotOK && (
            <StatusDecisionListItem
              browser={props.browser}
              navigator={props.navigator}
              blocking={blocking}
              message={t("decisions.antivirusNotOK")}
              buttonText={t("actions.enable")}
              showModal={false}
            />
          )}
          {notAffiliated && (
            <StatusDecisionListItem
              browser={props.browser}
              navigator={props.navigator}
              blocking={blocking}
              message={t("decisions.notAffiliated")}
              buttonText={t("actions.fix")}
              showModal={false}
            />
          )}
          {passwordmanagerNotOK && (
            <StatusDecisionListItem
              browser={props.browser}
              navigator={props.navigator}
              blocking={blocking}
              message={
                passwordmanagerNotEnabled
                  ? t("decisions.passwordmanagerNotEnabled")
                  : t("decisions.passwordmanagerNotOK")
              }
              buttonText={t("actions.fix")}
              showModal={false}
            />
          )}
        </>
      )}
      <div className="decisions-button-container">
        <NotNowButton
          onClick={() => {
            props.endTransaction(props.transaction);
          }}
          blocked={blocking}
        />
        <RefreshButton {...props} />
      </div>
      <div className="decisions-button-container-mobile">
        <RefreshButton {...props} />
        <NotNowButton
          onClick={() => {
            props.endTransaction(props.transaction);
          }}
          blocked={blocking}
        />
      </div>
    </div>
  );
};

const RefreshButton = (props: DecisionsProps) => {
  const { t } = useTranslation();

  return (
    <Button
      size="large"
      variant="contained"
      className="button-refresh"
      onClick={props.refreshTransactionStatus}
      disabled={props.refreshingTransaction}
      endIcon={
        props.refreshingTransaction && (
          <CircularProgress
            style={{ color: "inherit", width: "1rem", height: "1rem" }}
          />
        )
      }
    >
      <Typography className={"install-text"}>
        {t("Token.nok.recheck")}
      </Typography>
    </Button>
  );
};

export default Decisions;
