import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { OS, supportedDesktopOSes } from "../../../System/System";
import "./UpdateInstructionsModal.css";

interface UpdateInstructionsModalProps {
  platform: OS | null;
  open: boolean;
  handleClose: () => void;
}

const UpdateInstructionsModal: React.FC<UpdateInstructionsModalProps> = ({
  platform,
  open,
  handleClose,
}) => {
  const { t } = useTranslation();

  const instructionsMap: { [key in OS]?: string } = {
    Windows: t("Token.updateInstructions.windows"),
    macOS: t("Token.updateInstructions.macos"),
    Linux: t("Token.updateInstructions.linux"),
  };

  const isDesktopOS = platform
    ? supportedDesktopOSes.includes(platform)
    : false;

  const handlePlatformSpecificClose = () => {
    switch (platform) {
      case "Windows":
        console.log("Perform Windows-specific action");
        break;
      case "macOS":
        console.log("Perform macOS-specific action");
        break;
      case "Linux":
        console.log("Perform Linux-specific action");
        break;
      default:
        console.log("Handle for other or unknown platforms");
    }
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        className: "Token.updateInstructionsModal",
      }}
    >
      <DialogTitle>
        {t("Token.updateInstructions.title", { platform: platform })}
      </DialogTitle>
      <DialogContent>
        {platform && instructionsMap[platform] && (
          <Typography variant="body1">
            {(
              instructionsMap[platform ?? "Unknown"] ??
              "No instructions available."
            )
              .split("\n")
              .map((line, index) => (
                <span key={index}>
                  {line}
                  <br />
                </span>
              ))}
          </Typography>
        )}
      </DialogContent>
      {isDesktopOS && (
        <DialogActions>
          <Button onClick={handlePlatformSpecificClose} color="primary">
            {t("Token.updateInstructions.openSettings")}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default UpdateInstructionsModal;
